<template>
  <div class="content md">
    <div class="buddha-birthday">
      <div class="buddha-birthday-title">佛诞日列表</div>
      <div class="gap"></div>
      <a-radio-group class="buddha-birthday-mode" v-model="mode" v-if="false">
        <a-radio-button class="birthday-mode-item" value="calendar">
          <a-icon type="calendar" />
          <span class="birthday-mode-text">日历视图</span>
        </a-radio-button>
        <a-radio-button class="birthday-mode-item" value="list">
          <a-icon type="unordered-list" />
          <span class="birthday-mode-text">列表视图</span>
        </a-radio-button>
      </a-radio-group>
      <calendar
        ref="calendar"
        lunar
        @change="dateChange"
        @month="val => current = val"
        v-show="mode == 'calendar'">
        <template v-slot:date="{day}">
          <div class="buddha-birth-day" v-if="dayMap[day.dateStr]">
            <div class="buddha-birth-name" v-for="b in dayMap[day.dateStr]" :key="b.id">{{b.name}}</div>
          </div>
        </template>
      </calendar>
      <data-list
        ref="dataList"
        class="flex-grow"
        url="/buddha-birthday"
        entity-name="佛诞日佛诞日"
        add-btn="添加佛诞日"
        show-row-num
        hide-show-detail
        :action="action"
        :rules="rules"
        :columns="columns"
        :search-key-type="{name: 1}"
        :before-edit="beforeEdit"
        v-show="mode == 'list'">
        <template v-slot:query="{form}">
          <a-form-model-item label="">
            <a-input v-model="form.name" placeholder="输入业务名称查询"></a-input>
          </a-form-model-item>
        </template>
        <template v-slot:edit="{form}">
          <a-form-model-item label="名称" prop="name">
            <a-input v-model="form.name" placeholder="请输入佛诞日名称"></a-input>
          </a-form-model-item>
          <a-form-model-item label="日期" prop="date">
            <lunar-picker v-model="form.date" placeholder="请选择佛诞日日期" :disabled-date="disabledDate"/>
          </a-form-model-item>
        </template>
      </data-list>
    </div>
  </div>
</template>

<script >
    import calendar from "../../components/calendar/calendar";
    import {getLunarDay} from "../../components/calendar/lunar";
    import moment from "moment";
    import {getDate} from "../../common/js/tool";
    export default {
        name: "BuddhaBirthday",
        components: {
            calendar
        },
        data() {
            return {
                current: null,
                select: null,
                dayMap: {},
                mode: "list",
                rules: {
                    name: [{ required: true, message: '请输入佛诞日名称', trigger: 'blur' }],
                    date: [{ required: true, message: '请选择佛诞日日期', trigger: 'change' }],
                },
                columns: [
                    {title: '名称', dataIndex: 'name'},
                    {title: '日期', dataIndex: 'date', customRender: text => this.getShowDate(text)},
                ]
            }
        },
        computed: {
            action() {
                return this.$route.meta.action;
            }
        },
        created() {
            // this.getMonthDayList();
        },
        methods: {
            disabledDate: current => {
                return current && current >= moment().endOf('day')
            },
            getShowDate(date) {
                const day = getDate(date);
                return `${day.pattern("yyyy年M月d日")} 农历${getLunarDay(day,true)}`
            },
            beforeEdit(form, fn) {
                if(form.date) {
                    form.date = form.date.split(" ")[0];
                }
                fn();
            },
            getMonthDayList() {
                let url = "/admin/buddha-birthday?pageSize=1000";
                this.$axios(url).then(res => {
                    let dateMap = {};
                    res.data.forEach(item => {
                        let day = getDate(item.date).pattern("yyyy-MM-dd");
                        if(dateMap[day]) {
                            dateMap[day].push(item);
                        } else {
                            dateMap[day] = [item];
                        }
                    })
                    this.dateMap = dateMap;
                })
            },
            dateChange(date) {
                this.select = date;
            },
        }
    }
</script>

<style scoped lang="less">
  .buddha-birthday {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }
  .buddha-birthday-title {
    text-align: center;
    line-height: 32px;
    font-size: 18px;
    font-weight: 700;
  }
  .buddha-birthday-mode {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
</style>
