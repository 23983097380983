<template>
  <form-area
    ref="addForm"
    class="content md"
    layout="horizontal"
    :label-col="{span: 4}"
    :wrapper-col="{span: 20}"
    :items="items"
    :entity="form"
    @cancel="handleCancel"
    @confirm="handleConfirm"></form-area>
</template>

<script>
    import {buddhaCategoryList, getBuddhaCategoryTypeList, getBuddhaTypeName, hasExplanation} from "../../common/buddha/type";

    export default {
        name: "BuddhaTypeAdd",
        data() {
            return {
                form: {},
                items: [
                    {
                        key: 'cat',
                        label: '所属大类',
                        component: 'a-select',
                        props: {
                            placeholder: '请选择',
                            options: buddhaCategoryList
                        },
                        listeners: {
                            change: function (val) {
                                let index = this.controls.findIndex(c => c.key == 'fs_type');
                                if(index === -1) {
                                    this.controls.splice(1, 0, {
                                        key: 'fs_type',
                                        label: '类别',
                                        component: 'a-select',
                                        props: {
                                            placeholder: '请选择',
                                            options: getBuddhaCategoryTypeList(val)
                                        },
                                        listeners: {
                                            change: function (type) {
                                                this.$set(this.form, 'name', getBuddhaTypeName(type));
                                                let i = this.controls.findIndex(c => c.key == 'detail');
                                                if(hasExplanation(type) && i === -1) {
                                                    this.controls.push({
                                                        key: 'detail',
                                                        label: '说明',
                                                        component: 'rich-editor',
                                                        props: {
                                                            placeholder: '请输入说明'
                                                        },
                                                    })
                                                } else if(i >= 0) {
                                                    this.controls.splice(i, 1);
                                                }
                                            }
                                        },
                                        rules: [{ required: true, message: '请选择类别', trigger: 'change' }]
                                    })
                                } else {
                                    this.$set(this.controls[index].props, 'options', getBuddhaCategoryTypeList(val));
                                }
                            }
                        },
                        rules: [{ required: true, message: '请选择所属大类', trigger: 'change' }]
                    },
                    {
                        key: 'name',
                        label: '名称',
                        component: 'a-input',
                        props: {
                            placeholder: '请输入名称',
                        },
                        rules: [{ required: true, message: '请输入名称', trigger: 'blur' }]
                    },
                    {
                        key: 'intro',
                        label: '介绍',
                        component: 'rich-editor',
                        props: {
                            placeholder: '请输入介绍'
                        },
                    },
                ]
            }
        },
        methods: {
            handleCancel() {
                this.$router.back();
            },
            handleConfirm(form) {
                this.$axios({
                    url: '/admin/fs-temp',
                    method: 'POST',
                    data: form
                }).then(res => {
                    this.$message.success('添加成功');
                    this.$router.replace({name: 'BuddhaTypeDetail', params: {id: res.id}});
                });
            },
        }
    }
</script>

<style scoped>

</style>
